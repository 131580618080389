import { mapActions, mapGetters } from 'vuex'

const Modal = () => import(/* webpackChunkName = "c-modal" */'@/components/Modal')
const emptyField = (name) => `${name} tidak boleh kosong`

export default {
  name: 'AddEventForm',
  Components: {
    Modal
  },
  props: {
    eventData: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      theme: '',
      chooseDate: '',
      pastorName: '',
      pastorAssistantName: '',
      greeterNames: '',
      choirName: '',
      chantingGuideName: '',
      bandName: '',
      multimediaName: '',
      isEdit: false,
      errors: [],
      selectedTabs: 'PUBLIC',
      info: '',
      liturgosName: '',
      singerName: '',
      className: '',
      youtubeVideoId: '',
      serviceValue: '',
      serviceType: ''
    }
  },
  created () {
    this.initPage()
  },
  computed: {
    ...mapGetters('user', ['token']),
    requestData () {
      const dateInMillis = new Date(this.chooseDate).getTime()
      return {
        id: this.eventData.id,
        event: {
          bandName: this.bandName,
          chantingGuideName: this.chantingGuideName,
          choirName: this.choirName,
          className: this.className,
          eventDate: dateInMillis,
          greeterNames: this.greeterNames,
          info: this.info,
          liturgosName: this.liturgosName,
          multimediaName: this.multimediaName,
          pastorAssistantName: this.pastorAssistantName,
          pastorName: this.pastorName,
          singerName: this.singerName,
          theme: this.theme,
          token: this.token,
          type: this.selectedTabs,
          youtubeVideoId: this.youtubeVideoId,
          serviceValue: this.serviceValue,
          serviceType: this.serviceType
        }
      }
    },
    errorsText () {
      return this.errors.join(', ')
    }
  },
  methods: {
    ...mapActions('event', ['createEventAction', 'editEventAction', 'getEventsAction']),
    close () {
      this.$emit('close')
    },
    initPage () {
      if (Object.keys(this.eventData).length > 0) {
        this.isEdit = true
        const { theme, type, pastorName, pastorAssistantName,
          greeterNames, multimediaName, choirName, chantingGuideName,
          bandName, className, liturgosName, singerName, info, eventDate,
          youtubeVideoId, serviceValue, serviceType } = this.eventData
        this.selectedTabs = type
        const isoDate = new Date(eventDate).toISOString()
        this.pastorName = pastorName
        this.theme = theme
        this.pastorAssistantName = pastorAssistantName
        this.greeterNames = greeterNames
        this.multimediaName = multimediaName
        this.choirName = choirName
        this.chantingGuideName = chantingGuideName
        this.bandName = bandName
        this.className = className
        this.liturgosName = liturgosName
        this.singerName = singerName
        this.info = info
        this.chooseDate = isoDate
        this.youtubeVideoId = youtubeVideoId
        this.serviceValue = serviceValue
        this.serviceType = serviceType
      }
    },
    handleSubmit () {
      this.validateForm()
      if (this.errors.length === 0) {
        if (this.isEdit) {
          this.editEventAction({
            data: this.requestData,
            success: this.handleSuccessSubmit
          })
        } else {
          this.createEventAction({
            data: this.requestData,
            success: this.handleSuccessSubmit
          })
        }
      }
    },
    handleSuccessSubmit () {
      this.$swal({
        icon: 'success',
        title: 'Success Submit',
        showConfirmButton: false,
        timer: 1500
      })
      this.getEventsAction({
        success: () => {}
      })
      this.close()
    },
    validateForm () {
      this.errors = []
      if (this.theme === '') this.errors.push(emptyField('Tema'))
      if (this.chooseDate === '') this.errors.push(emptyField('Tanggal'))
      if (this.selectedTabs === 'PUBLIC') {
        if (this.pastorName === '') this.errors.push(emptyField('Pelayan Firman'))
        if (this.pastorAssistantName === '') this.errors.push(emptyField('Penatua Pendamping'))
        if (this.greeterNames === '') this.errors.push(emptyField('Penatua Penyambut'))
        if (this.choirName === '') this.errors.push(emptyField('Paduan Suara'))
        if (this.chantingGuideName === '') this.errors.push(emptyField('Pemandu Musik'))
        if (this.bandName === '') this.errors.push(emptyField('Pemusik'))
        if (this.multimediaName === '') this.errors.push(emptyField('Multimedia'))
      }
      if (this.selectedTabs === 'TEEN') {
        if (this.pastorName === '') this.errors.push(emptyField('Pelayan Firman'))
        if (this.pastorAssistantName === '') this.errors.push(emptyField('Penatua Pendamping'))
        if (this.liturgosName === '') this.errors.push(emptyField('Liturgos'))
        if (this.singerName === '') this.errors.push(emptyField('Singer'))
        if (this.bandName === '') this.errors.push(emptyField('Pemusik'))
        if (this.info === '') this.errors.push(emptyField('Keterangan'))
      }
      if (this.selectedTabs === 'KIDS') {
        if (this.className === '') this.errors.push(emptyField('Kelas'))
      }
    },
    changeTabs (val) {
      if (this.isEdit) return
      this.selectedTabs = val
      this.errors = []
    }
  }
}
